import React, { FC } from 'react';
import { Seos } from '@/components/common/Seos';
import { LayoutProps } from '@/components/layout/LayoutProps';
import { LightThemeLayoutDefender } from '@/components/layout/LightThemeLayoutDefender';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

type Props = LayoutProps;

export const EmptyLandingLayout: FC<Props> = ({
  children,
  layoutOptions,
}) => {
  const { isKnowelyProduct } = useSubDomainContext();

  return (
    <>
      <Seos seo={layoutOptions?.seo} />
      {!isKnowelyProduct && <LightThemeLayoutDefender />}

      {children}
    </>
  );
};
