import { useEffect } from 'react';
import { BG_BACKFILL_CLASS, THEME_ATTRIBUTE } from '@/components/common/ThemeContext/constants';

export const LightThemeLayoutDefender = () => {
  useEffect(() => {
    const root = document.documentElement;

    root.removeAttribute(THEME_ATTRIBUTE);
    root.classList.remove(BG_BACKFILL_CLASS);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach(({ type, attributeName }) => {
        if (type !== 'attributes') {
          return;
        }

        if (
          attributeName === THEME_ATTRIBUTE
          && root.hasAttribute(THEME_ATTRIBUTE)
        ) {
          root.removeAttribute(THEME_ATTRIBUTE);
        }

        if (
          attributeName === 'class'
          && root.classList.contains(BG_BACKFILL_CLASS)
        ) {
          root.classList.remove(BG_BACKFILL_CLASS);
        }
      });
    });

    observer.observe(root, {
      attributes: true,
      attributeFilter: ['class', THEME_ATTRIBUTE],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return null;
};
